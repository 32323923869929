import React from "react";
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Section, SectionTitle, SectionDescription } from "../../components/Section"

const Grid = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
`
const GridItem = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width: 992px) { 
	flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`
const MediaThumbnail = styled.div`
    position:relative;
    border-radius:15px;
    background-color:#fff;
    border: 1px solid #f5f5f5;
    box-shadow:0 0 5px rgba(0,0,0,0.05);
    margin-bottom:30px;
    height:calc(100% - 30px);
`
const MediaBody = styled.div`
    padding:15px 15px 20px;
`
const MediaFigure = styled.div`
  .gatsby-image-wrapper{
    display:block;
    img{
      border-radius:15px;
    }
  } 
`
const MediaCaption = styled.div`
  position:relative;
  text-align:center;  
  padding:10px 15px;
  margin: -30px 10px 0;
  &:before{
    content: "";
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid #f5f5f5;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
  }
`
const MediaTitle = styled.div`
    font-family: 'Eina03-Bold';
    position:relative;
    text-transform: uppercase;
    color:#000;
    font-weight:700;
    font-size:18px;
    line-height:28px; 
    @media(min-width:1200px){
        font-size:20px;
        line-height:30px; 
    }
`
const MediaDesc = styled.div`	
	text-align:center;
	p{
		font-family: 'Eina03-Regular';
        margin: 15px 0px;
        font-size: 16px;
        line-height: 28px;
        color: #000;

	}
`

const RoofStyle = () => {
    return (
        <Section pt="60px" pb="40px" mpt="40px" mpb="20px" spt="30px" spb="10px">
            <div className="container">
                <SectionTitle>Roof Styles for Metal Buildings</SectionTitle>
                <SectionDescription maxWidth="950px"><p>Choosing the right roof style for your metal building is important for both durability and aesthetic appeal. Here are the three most popular roof styles for steel structures.</p></SectionDescription>
                <Grid>
                    <GridItem>
                        <MediaThumbnail>
                            <MediaBody>
                                <MediaFigure>
                                    <StaticImage
                                        src="../../images/regular-style-roof.png"
                                        placeholder="blurred"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        alt="limited warranty"
                                    />
                                </MediaFigure>
                                <MediaCaption><MediaTitle>Regular Roof Style</MediaTitle></MediaCaption>
                                <MediaDesc><p>Regular roof styles are the perfect combination of affordability, elegance, and functionality. It features horizontal panels and rounded edges, which provide reliable protection against rain, snow, and debris. Perfect for areas experiencing mild weather conditions. </p></MediaDesc>
                            </MediaBody>
                        </MediaThumbnail>
                    </GridItem>
                    <GridItem>
                        <MediaThumbnail>
                            <MediaBody>
                                <MediaFigure>
                                    <StaticImage
                                        src="../../images/boxed-eave-roof.png"
                                        placeholder="blurred"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        alt="limited warranty"
                                    />
                                </MediaFigure>
                                <MediaCaption><MediaTitle>Boxed-Eave Roof Style</MediaTitle></MediaCaption>
                                <MediaDesc><p>Boxed-eave roof style is perfect for protecting your cars, equipment, and other valuables. The horizontal panels in this roof style create an "A" shape, which prevents snow and debris buildup. This roof style is great for areas experiencing moderate weather conditions.</p></MediaDesc>
                            </MediaBody>
                        </MediaThumbnail>
                    </GridItem>
                    <GridItem>
                        <MediaThumbnail>
                            <MediaBody>
                                <MediaFigure>
                                    <StaticImage
                                        src="../../images/vertical-style-roof.png"
                                        placeholder="blurred"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        alt="limited warranty"
                                    />
                                </MediaFigure>
                                <MediaCaption><MediaTitle>Vertical Roof Style</MediaTitle></MediaCaption>
                                <MediaDesc><p>The vertical roof style offers superior strength and efficient water drainage. With vertically-oriented panels, it easily sheds rain, snow, leaves, and other accumulations. This style is perfect for areas experiencing extreme weather conditions.</p></MediaDesc>
                            </MediaBody>
                        </MediaThumbnail>
                    </GridItem>
                </Grid>
            </div>
        </Section>
    )
}

export default RoofStyle