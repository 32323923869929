import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RightArrowIcon from "../Icons/RightArrowIcon"
import LeftArrowIcon from "../Icons/LeftArrowIcon"

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  .title {
    font-size: 22px;
    line-height: 34px;
    margin-top: 89px;
    margin-bottom: 14px;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 30vw;
    max-height: 390px;
  }
  @media(max-width: 992px) {
    .gatsby-image-wrapper {
      height: 60vw;
      max-height: 450px;
    }
  }
`

const ArrowWrapper = styled.div`
  position: relative;
`

const RightArrow = styled.div`
  position: absolute;
  width: 56px;
  height: 56px;
  top: 20px;
  left: 66px;
  padding: 15px;
  border: 2px solid #D0D0D0;
  border-radius: 60px;
  z-index: 10;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease;
      transform: translateX(5px);
    }
  }
 
`
const LeftArrow = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  width: 56px;
  height: 56px;
  padding: 15px;
  border: 2px solid #D0D0D0;
  border-radius: 60px;
  z-index: 10;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease;
      transform: translateX(-5px);
    }
  }
`

function SampleNextArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
    />
  );
}


export default class ImageSlider extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const { data } = this.props
   
    const settings = {
      dots: false,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      autoplay: true,
      autoplaySpeed: 2000,
    };
    return (
      <Wrapper >
        <Slider {...settings} ref={c => (this.slider = c)}>
          {
            data.map((item, i)=> {
              return(
                <div>
                  {/* <h2 className="title">{item.name}</h2> */}
                  <GatsbyImage image={getImage(item.image.gatsbyImageData)} alt='Buildings And More'/>
                </div>
              )
            })
          }
        </Slider>
        { data.length > 1 && (
          <ArrowWrapper>
            <LeftArrow onClick={this.previous}>
              <LeftArrowIcon />
            </LeftArrow>
            <RightArrow onClick={this.next}>
              <RightArrowIcon />
            </RightArrow>
          </ArrowWrapper>
        )}
      </Wrapper>
    );
  }
}