import React from 'react';
import styled from 'styled-components';

const SectionWrap = styled.div`
	background: #381F1F;
	padding: 53px 0 70px;
	color: #fff;

	h5, .h5 {
		color: #fff;
	}

	* {
		color: #fff;
		max-width: 890px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	li {
		margin-left: 20px;
	}
`

const SizesWrap = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.size {
		padding: 8px 23px;
		width: fit-content;
		font-size: 18px;
		line-height: 1;
		border: 1px solid rgba(255, 255, 255, 0.2);
		border-radius: 50px;
		margin-right: 10px;
		margin-top: 10px;
		margin-left: 0;
	}
`

const AvailableSection = ({ data }) => (
	<SectionWrap>
		<div className="container">
			<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
			{data.sizes?.length > 0 && (
				<>
					<span className='h5'>{data.title}</span>
					<SizesWrap>
					{
						data.sizes.map((item, i) => (
							<div className="size" key={`size-${i}`}>
								{item.content}
							</div>
						))
					}
					</SizesWrap>
				</>
			)}
			
		</div>
	</SectionWrap>
);

export default AvailableSection;