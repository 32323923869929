import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import SecondButton from "../../components/Button/SecondButton";
import LineArrowRight from "../../components/Icons/LineArrowRight"
import ShedIcon from "../../components/Icons/ShedIcon"
import ThreeDIcon from "../../components/Icons/ThreeDIcon"
import { SectionPageTitle } from "../../components/Section";
import RightArrowIcon from "../../components/Icons/RightArrowIcon";
import ImageSlider from "../../components/ImageSlider"

const HeroWrap = styled.div`
	position: relative;
	z-index: 2;

	.bg-wrap {
		position: absolute;
		width: 100%;
		height: calc(100% - 36px);

		.gatsby-image-wrapper {
			height: 100%;
		}
	}

	.container {
		display: flex;
		align-items: center;
		.slider-wrap {
			position: relative;
			width: 50%;
			padding: 50px 0 150px;

			h2 {
				margin: 10px 0 !important;
			}
		}

		.right-wrap {
			position: relative;
			width: 50%;
			background: white;
			margin-top: -36px;
			box-shadow: 0 20px 55px rgba(0, 0, 0, 0.45);
			min-height: 680px;
		}

		@media(max-width: 992px) {
			flex-wrap: wrap;

			.slider-wrap {
				width: 100%;
			}
			.right-wrap {
				width: 100%;
			}
		}
		@media(max-width: 480px) {
			width: 100%;
		}
	}

	.text-wrap {
		position: relative;
		width: 100%;
		padding: 56px 0 63px;

		.sub-title {
			font-size: 20px;
			line-height: 32px;
			padding: 0 50px;
			text-align: center;
			margin-bottom: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.description {
			font-size: 18px;
			line-height: 30px;
			padding: 0 50px;
			text-align: center;
			margin: 0;
			margin-bottom: 25px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
		}
		.sub-category {
			position: relative;
			display: flex;
			align-items: center;
			width: calc(100% - 120px);
			margin: auto;
			margin-bottom: 2px;
			justify-content: space-between;
			padding: 15px 0;
			border-bottom: 1px solid #D0D0D0;
			color: #000000;

			&:before {
				position: absolute;
				content: ' ';
				left: -59px;
				width: 2px;
				height: 100%;
				background: #E43D3C;
			}
			&:hover {
				cursor: pointer;
				color: #E43D3C;
				svg {
					path {
						stroke: #E43D3C;
					}
				}
			}
		}
	}
`

const IconWrap = styled.div`
	position: absolute;
	z-index: 101;
	top: ${props => props.top};
	left: ${props => props.left};
	bottom: ${props => props.bottom};
	width: 26px;
	height: 36px;

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	@media(max-width: 992px) {
		display: none;
	}
`

IconWrap.defaultProps = {
	top: 'auto',
	left: '0',
	bottom: 'auto',
}
const ExploreButton = styled.div`
	text-align: center;
	margin-top:30px;
`

const HeroSection = ({ data }) => {	
	const isPortableBuildings = data.category === "Portable Buildings" ? true : false;

	return(
		<HeroWrap>
			<div className="bg-wrap">
				<StaticImage
	        src="../../images/top_mid.png"
	        placeholder="blurred"                          
	        alt="hero text banner"
	      />
			</div>
			<div className="container">
				<div className="slider-wrap">
					<ImageSlider data={data.subCategories} />
				</div>
				<div className="right-wrap">
					<IconWrap top="0" left="-26px">
						<StaticImage
			        src="../../images/left-top-triangle.png"
			        placeholder="blurred"
			        quality={95}
			        formats={["AUTO", "WEBP", "AVIF"]}
			        alt="About"
			      />
					</IconWrap>
					<div className="text-wrap">
						<SectionPageTitle mt="10px" mb="10px">{data.title}</SectionPageTitle>
						<p className="sub-title">{data.subTitle}</p>
						<p className="description">{data.description.heroDescription}</p>
						<div>
						{ data.subCategories && (
							data.subCategories?.map((item, i) => {
								if (item.visibleInCategoryLanding) {
									return(
										<Link to={item.category.url + item.slug}>
											<div className="sub-category" key={i}>
												{item.name} <RightArrowIcon />
											</div>
										</Link>
									)
								} else {
									return true;
								}
							})
						)}
						</div>
						{/* {data.category ==  'Portable Buildings' &&

							<ExploreButton>
								<a href="https://bambuildingsheds.senseisheds.com/" target="_blank" rel="noreferrer">
									<SecondButton text="Shed Designer" preIcon={<ShedIcon fill="#fff" />} icon={<LineArrowRight />} />
								</a>
							</ExploreButton>
						}
						{data.category ==  'Steel Buildings' &&
							<ExploreButton>
								<a href="https://bambuildingsheds.senseisheds.com/" target="_blank" rel="noreferrer">
									<SecondButton text="Carport Designer" preIcon={<ThreeDIcon fill="#fff" />} icon={<LineArrowRight />} />
								</a>
							</ExploreButton>
						} */}

						<ExploreButton>
								<a href={isPortableBuildings ? "https://bambuildingsheds.senseisheds.com/" : "https://buildingsandmore.sensei3d.com/" } target="_blank" rel="noreferrer">
									<SecondButton text={ isPortableBuildings ? "3D Shed Designer" : "3D Carport Designer"} preIcon={isPortableBuildings ? <ShedIcon fill="#fff" /> :<ThreeDIcon fill="#fff" />} icon={<LineArrowRight />} />
								</a>
							</ExploreButton>

					</div>
					<IconWrap bottom="0" left="-26px">
						<StaticImage
			        src="../../images/left-bottom-triangle.png"
			        placeholder="blurred"
			        quality={95}
			        formats={["AUTO", "WEBP", "AVIF"]}
			        alt="About"
			      />
					</IconWrap>
					
				</div>
			</div>
		</HeroWrap>
	)
}

export default HeroSection

