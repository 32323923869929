import React from 'react'

const LeftArrowIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.688" height="20.087" viewBox="0 0 21.688 20.087">
      <g id="Group_9466" data-name="Group 9466" transform="translate(2.191 2.384)">
        <path id="Path_43" data-name="Path 43" d="M2396.961,23H2382" transform="translate(-2378.964 -14.588)" fill="none" stroke={fill} stroke-linecap="round" stroke-width="3"/>
        <path id="Path_44" data-name="Path 44" d="M6.855,11.873,9.969,2.16,0,0" transform="translate(8.634 15.267) rotate(-150)" fill="none" stroke={fill} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </g>
    </svg>

  )
}

LeftArrowIcon.defaultProps = {
  fill: '#000',
}
export default LeftArrowIcon;