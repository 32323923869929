
import React from 'react'

const RightArrowIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.687" height="20.087" viewBox="0 0 21.687 20.087">
      <g id="Group_9400" data-name="Group 9400" transform="translate(-1348.5 -37.032)">
        <path id="Path_43" data-name="Path 43" d="M2382,23h14.96" transform="translate(-1032 24.827)" fill="none" stroke={fill} stroke-linecap="round" stroke-width="3"/>
        <path id="Path_44" data-name="Path 44" d="M6.861,0,9.975,9.717.006,11.876" transform="translate(1353.42 44.4) rotate(-30)" fill="none" stroke={fill} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </g>
    </svg>
  )
}
RightArrowIcon.defaultProps = {
  fill: "#000",
}
export default RightArrowIcon;